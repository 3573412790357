import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'store'
import { formatDate } from 'helpers/dates'
import { useAdminGetAssetsQuery, useAdminListThemesQuery } from 'api'
import AdminTable from 'components/admin/tables'
import Pagination from 'components/pagination'
import DeregisterAssetModal from 'components/modals/forms/asset_owner/DeregisterAssetModal'
import { useSort } from 'hooks/useSort'
import Select from 'react-select'
import { useFilters } from 'hooks/useFilters'
import SearchInput from 'components/admin/search_input'
import RegistrationFilter from 'enums/RegistrationFilter'
import { Badge } from 'components/badge'
import { AssetType, getFriendlyAssetType } from 'enums/AssetType'
import { AdminAccessRequest } from 'components/modals/forms/access-request'
import Asset from 'api/types/models/asset'
import { useAdminGetAccessRequestsQuery } from 'api/endpoints/admin/request-access'
import { MfaRequestStatus } from 'enums/MfaRequestStatus'
import { UserRole } from 'enums/UserRole'
import { AccessRequestLayout } from 'layouts/AccessRequestLayout'
import ToolTip from 'components/tooltip'
import { AssetDomains } from 'enums/AssetDomains'
import { AssetSpecs, getAssetSpecName } from 'enums/AssetSpecs'
import Input from 'components/form/input'
import Button from 'components/button'

type AssetFilters = {
  search: string
  theme_id: number[]
  is_registered: RegistrationFilter
  domain: AssetDomains | null,
  product_type: AssetType | null,
  payload_spec: AssetSpecs | null,
  manufacture_year: string | null,
}

const AdminAssetBrowsePage = () => {
  const authUser = useSelector((state: RootState) => state.auth.user)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedAsset, setSelectedAsset] = useState<number>(0)

  const [openAccessRequestModal, setOpenAccessRequestModal] = useState(false)
  const [accessRequestAsset, setAccessRequestAsset] = useState<Asset | null>(null)

  const [currentPage, setCurrentPage] = useState<number>(1)

  const registrationLabels = [
    { label: 'Registered', value: RegistrationFilter.REGISTERED },
    { label: 'Not Registered', value: RegistrationFilter.NOT_REGISTERED },
  ]

  const { sorts, toggleSorts } = useSort({
    last_name: undefined,
    description: undefined,
    is_private: undefined,
    last_scanned: undefined,
    registered_at: undefined,
    theme: undefined,
    product: undefined,
  }, () => setCurrentPage(1))

  const [{ filters, debouncedFilters }, setFilters] = useFilters<AssetFilters>({
    onFilterChange: () => setCurrentPage(1),
    initialFilters: {
      search: '',
      theme_id: [],
      is_registered: RegistrationFilter.REGISTERED,
      manufacture_year: null,
      domain: null,
      product_type: null,
      payload_spec: null,
    },
  })

  const {
    data: assets,
    isLoading,
    isFetching,
  } = useAdminGetAssetsQuery({
    page: currentPage,
    sort: sorts,
    search: debouncedFilters.search,
    filter: {
      theme_id: debouncedFilters.theme_id,
      is_registered: debouncedFilters.is_registered,
      manufacture_year: (
        parseInt(debouncedFilters?.manufacture_year ?? '') >= 2020 && 
        parseInt(debouncedFilters?.manufacture_year ?? '') <= 2100) ? debouncedFilters.manufacture_year : null,
      domain: debouncedFilters.domain,
      product_type: debouncedFilters.product_type,
      payload_spec: debouncedFilters.payload_spec,
    },
  }, {
    refetchOnMountOrArgChange: true,
  })

  const {
    data: accessRequests,
  } = useAdminGetAccessRequestsQuery({})

  const { data: themes } = useAdminListThemesQuery({})

  const openAssetRemovalModal = (assetId: number) => {
    setSelectedAsset(assetId)
    setIsModalOpen(true)
  }

  const parsedAssets = useMemo(() => {
    return assets?.data?.map(asset => {
      const accessRequest = accessRequests?.find(request => request.user_id === asset?.user_id)

      let hasAccess = false

      // If the asset is not registered, then any admin can access the asset
      if (!asset?.user_id) {
        hasAccess = true
      }

      // If the auth user is super admin, then they can access everything
      if (authUser?.role === UserRole.SUPER_ADMIN) {
        hasAccess = true
      }

      // If the admin has a redeemed, non-expired access request, then they can access the asset
      if (accessRequest && accessRequest?.status === MfaRequestStatus.REDEEMED) {
        hasAccess = true
      }

      return {
        ...asset,
        has_access: hasAccess,
        access_request: accessRequest,
      }
    })
  }, [assets, accessRequests])

  return (
    <AccessRequestLayout blockRedirect={true}>
      <div className="flex flex-col h-full">
        <div className="flex justify-start p-3 border-b lg:justify-between lg:p-6 lg:items-center border-secondary-gray">
          <h1 className="text-2xl font-medium text-slate-800">
            Assets
          </h1>
          <div className="flex gap-2">
            <Button href="/admin/dashboard/assets/import" className="hidden lg:block">
              Import
            </Button>
          </div>
        </div>

        <div className="flex flex-wrap items-start gap-3 p-3 lg:p-6">
          <SearchInput
            isLoading={isLoading}
            value={filters?.search}
            onChange={(input) => setFilters({ ...filters, search: input })}
          />

          <Select
            isMulti
            isLoading={isFetching}
            placeholder="Filter Themes"
            classNamePrefix="react-select"
            className="react-select-container"
            onChange={(options) => setFilters({ theme_id: options.map((option) => option.value) } as AssetFilters)}
            options={themes?.map((theme) => ({ value: theme.id, label: `${theme.title} (${theme?.asset_count > 999 ? '999+' : theme?.asset_count})` }))}
          />

          <Select
            isClearable
            isLoading={isFetching}
            defaultValue={{ label: 'Registered', value: RegistrationFilter.REGISTERED }}
            
            placeholder="Filter Registration"
            classNamePrefix="react-select"
            className="react-select-container"
            onChange={(option) => setFilters({
              ...filters,
              is_registered: option ? option.value : RegistrationFilter.ALL,
            })}
            options={registrationLabels}
          />

          <Select
            isClearable
            placeholder="Filter Domain"
            classNamePrefix="react-select"
            className="react-select-container"
            onChange={(option) => {
              setFilters({
                ...filters,
                domain: option ? option.value : null,
              })
            }
            }
            options={Object.values(AssetDomains).map(domain => ({label: domain, value: domain}))}
          />

          <Select
            isClearable
            placeholder="Filter Product Type"
            classNamePrefix="react-select"
            className="react-select-container"
            onChange={(option) => {
              setFilters({
                ...filters,
                product_type: option ? option.value : null,
              })
            }
            }
            options={Object.values(AssetType).map(product => ({label: getFriendlyAssetType(product), value: product}))}
          />

          <Select
            isClearable
            placeholder="Filter Payload Specification"
            classNamePrefix="react-select"
            className="react-select-container"
            onChange={(option) => {
              setFilters({
                ...filters,
                payload_spec: option ? option.value : null,
              })
            }
            }
            options={Object.values(AssetSpecs).map(spec => ({label: getAssetSpecName(spec), value: spec}))}
          />
          <div className="max-w-[384px] max-h-[38px] w-full">
            <Input
              value={filters.manufacture_year ?? undefined} 
              onChange={input => {
                setFilters({...filters, manufacture_year: input.target.value})
              }}
              className="py-1.5 px-2"
              placeholder='Manufacture Year'
              type='number'
              maxLength={4}
            />
          </div>
        </div>
        <div className="px-3 pb-3 overflow-x-scroll lg:px-6 lg:pb-6">
          <AdminTable isLoading={isLoading}>
            <AdminTable.Head>
              <AdminTable.Heading>
                #
              </AdminTable.Heading>
              <AdminTable.Heading>
                S/N
              </AdminTable.Heading>
              <AdminTable.Heading
                sortable
                direction={sorts.last_name}
                onClick={() => toggleSorts('last_name')}
              >
                Owner
              </AdminTable.Heading>
              <AdminTable.Heading
                sortable
                direction={sorts.product}
                onClick={() => toggleSorts('product')}
              >
                Product
              </AdminTable.Heading>
              <AdminTable.Heading>
                Domain
              </AdminTable.Heading>
              <AdminTable.Heading>
                Payload Spec
              </AdminTable.Heading>
              <AdminTable.Heading
                sortable
                direction={sorts.description}
                onClick={() => toggleSorts('description')}
              >
                Description
              </AdminTable.Heading>
              <AdminTable.Heading
                sortable
                direction={sorts.theme}
                onClick={() => toggleSorts('theme')}
              >
                Theme
              </AdminTable.Heading>
              <AdminTable.Heading
                sortable
                direction={sorts.is_private}
                onClick={() => toggleSorts('is_private')}
              >
                Visibility
              </AdminTable.Heading>
              <AdminTable.Heading
                sortable
                direction={sorts.last_scanned}
                onClick={() => toggleSorts('last_scanned')}
              >
                Last Scanned
              </AdminTable.Heading>
              <AdminTable.Heading
                sortable
                direction={sorts.registered_at}
                onClick={() => toggleSorts('registered_at')}
              >
                Registered
              </AdminTable.Heading>
              <AdminTable.Heading>
                Manufacture
              </AdminTable.Heading>
              <AdminTable.Heading>Actions</AdminTable.Heading>
            </AdminTable.Head>
            <AdminTable.Body>
              {
                parsedAssets?.map(asset => (
                  <AdminTable.Row key={asset.id}>
                    <AdminTable.Column>
                      {asset.id}
                    </AdminTable.Column>

                    <AdminTable.Column>
                      <ToolTip id={`asset_${asset.id}`} content={asset.unmasked_uid} tooltipStyle={{textAlign: 'center'}}>
                        <code className="text-primary-gray">
                          {asset.serial || '-'}
                        </code>
                      </ToolTip>
                    </AdminTable.Column>

                    <AdminTable.Column>
                      {
                        asset?.has_access ? (
                          <>
                            {
                              asset?.user_id ? (
                                <Link
                                  className="url-text"
                                  title={asset.user.full_name}
                                  to={`/admin/dashboard/users/manage/${asset.user_id}`}
                                >
                                  {`${asset.user.first_name} ${asset.user.last_name.charAt(0)}.`}
                                </Link>
                              ) : (
                                <span>-</span>
                              )
                            }
                          </>
                        ) : (
                          <>
                            {
                              asset?.user_id &&
                              <span>{`${asset.user.first_name} ${asset.user.last_name.charAt(0)}.`}</span>
                            }
                          </>
                        )
                      }
                    </AdminTable.Column>

                    <AdminTable.Column>
                      <Badge
                        size="sm"
                        intent={asset?.product.name === AssetType.KiCall ? 'info' : 'warning'}
                      >
                        {
                          asset.product?.id
                            ? asset.product.name
                            : '-'
                        }
                      </Badge>
                    </AdminTable.Column>
                    <AdminTable.Column>
                      {asset.domain}
                    </AdminTable.Column>
                    <AdminTable.Column>
                      {getAssetSpecName(asset.spec)}
                    </AdminTable.Column>
                    <AdminTable.Column>
                      {asset.description}
                    </AdminTable.Column>

                    <AdminTable.Column>
                      {asset.theme?.title}
                    </AdminTable.Column>

                    <AdminTable.Column>
                      {asset.is_private ? 'Private' : 'Public'}
                    </AdminTable.Column>

                    <AdminTable.Column>
                      {asset.latest_scan && formatDate(asset.latest_scan.created_at, 'dd/MM/yyyy (HH:mm)')}
                    </AdminTable.Column>

                    <AdminTable.Column>
                      {asset.registered_at && formatDate(asset.registered_at)}
                    </AdminTable.Column>
                    <AdminTable.Column>
                      {asset?.manufactured_at ? formatDate(asset.manufactured_at,'yyyy') : null} 
                    </AdminTable.Column>
                    <AdminTable.Column>
                      <div className="flex text-sm divide-x">
                        {
                          asset?.has_access ? (
                            <>
                              <Link to={`/admin/dashboard/assets/manage/${asset.id}`} className="px-1 url-text">
                                Manage
                              </Link>
                              {
                                asset?.user_id &&
                                <button className="px-1 text-red-500 hover:underline" onClick={() => openAssetRemovalModal(asset.id)}>
                                  Deregister
                                </button>
                              }
                              <Link to={`/admin/dashboard/assets/history/${asset.id}`} className="px-1 url-text">
                                History
                              </Link>
                            </>
                          ) : (
                            <button
                              className="px-1 text-primary hover:underline"
                              onClick={() => {
                                setAccessRequestAsset(asset)
                                setOpenAccessRequestModal(true)
                              }}
                            >
                              {asset?.access_request ? 'Verify Request' : 'Request Access'}
                            </button>
                          )
                        }
                      </div>
                    </AdminTable.Column>
                  </AdminTable.Row>
                ))
              }
            </AdminTable.Body>
          </AdminTable>
        </div>

        <div className="flex justify-end p-3 mt-auto border-t lg:p-6 border-secondary-gray">
          <Pagination
            onPage={currentPage}
            totalPages={assets?.meta?.last_page ?? 1}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>

        <DeregisterAssetModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)} assetId={selectedAsset}
        />

        <AdminAccessRequest
          userId={accessRequestAsset?.user_id ?? null}
          href={`/admin/dashboard/assets/manage/${accessRequestAsset?.id}`}
          isOpen={openAccessRequestModal}
          onClose={() => setOpenAccessRequestModal(false)}
        />
      </div>
    </AccessRequestLayout>

  )
}

export default AdminAssetBrowsePage